/* eslint-disable class-methods-use-this */
import client from '@/http/client';
import { download, repackResponseFile } from '@/utils/common';

const BASE_URL = '/issues';

class IssueService {
  getIssue(
    { status, forMe, orderBy, search, limit, offset, projects, buildings, units, clients, performers, employees } = {},
    config
  ) {
    return client.post(
      `${BASE_URL}/select/issues/`,
      {
        buildings,
        units,
        projects,
        clients,
        performers,
        employees,
      },
      {
        ...config,
        params: {
          limit,
          offset,
          search,
          orderBy,
          status,
          forMe,
        },
      }
    );
  }

  getRecurrenceIssue({ forMe, limit, offset, orderBy, config, search }) {
    return client.get(`${BASE_URL}/recurring/`, {
      ...config,
      params: {
        limit,
        offset,
        orderBy,
        search,
        forMe,
      },
    });
  }

  getIssueById(issueId) {
    return client.get(`${BASE_URL}/for_employees/${issueId}/`);
  }

  getRecurrenceIssueById(issueId) {
    return client.get(`${BASE_URL}/recurring/${issueId}/`);
  }

  changeIssueStatus({ issueId, comment, newStatus, isShowDetailsToClient, files }) {
    return client.patch(`${BASE_URL}/for_employees/${issueId}/change_status/`, {
      comment,
      newStatus,
      isShowDetailsToClient,
      files,
    });
  }

  createIssue({
    name,
    description,
    performers,
    unit,
    project,
    clientForTask,
    serviceType,
    price,
    isInformClientViaPush,
    media,
  }) {
    return client.post(`${BASE_URL}/for_employees/`, {
      name,
      description,
      performers,
      unit,
      project,
      client: clientForTask,
      serviceType,
      price,
      isInformClientViaPush,
      media,
    });
  }

  createRecurrenceIssue({
    name,
    description,
    issueDateFirst,
    issueDateLast,
    recurrencePeriod,
    performer,
    unit,
    project,
    clientForTask,
    serviceType,
    price,
    isInformClientViaPush,
    media,
  }) {
    return client.post(`${BASE_URL}/recurring/`, {
      name,
      description,
      issueDateFirst,
      issueDateLast,
      recurrencePeriod,
      performer,
      unit,
      project,
      client: clientForTask,
      serviceType,
      price,
      isInformClientViaPush,
      media,
    });
  }

  updateIssueById({
    name,
    description,
    performers,
    unit,
    project,
    clientForTask,
    serviceType,
    price,
    isInformClientViaPush,
    media,
    taskId,
  }) {
    return client.patch(`${BASE_URL}/for_employees/${taskId}/`, {
      name,
      description,
      performers,
      unit,
      project,
      client: clientForTask,
      serviceType,
      price,
      isInformClientViaPush,
      media,
    });
  }

  updateRecurrenceIssueById({
    name,
    description,
    issueDateFirst,
    issueDateLast,
    recurrencePeriod,
    performer,
    unit,
    project,
    clientForTask,
    serviceType,
    price,
    isInformClientViaPush,
    media,
    taskId,
  }) {
    return client.patch(`${BASE_URL}/recurring/${taskId}/`, {
      name,
      description,
      issueDateFirst,
      issueDateLast,
      recurrencePeriod,
      performer,
      unit,
      project,
      client: clientForTask,
      serviceType,
      price,
      isInformClientViaPush,
      media,
    });
  }

  deleteIssueById(issueId) {
    return client.delete(`${BASE_URL}/for_employees/${issueId}/`);
  }

  deleteRecurrenceIssueById(issueId) {
    return client.delete(`${BASE_URL}/recurring/${issueId}/`);
  }

  exportIssue({ status, forMe, projects, buildings, units, clients, employees } = {}, config) {
    return client.postRaw(
      `${BASE_URL}/for_employees/xlsx/`,
      {
        buildings,
        units,
        projects,
        clients,
        employees,
      },
      {
        ...config,
        params: {
          status,
          forMe,
        },
        responseType: 'blob',
      }
    );
  }

  async downloadTasks(filter, fallbackFilename) {
    const response = await this.exportIssue(filter);

    const { filename, blob } = repackResponseFile(response, fallbackFilename);

    download(filename, blob);
  }
}

export default new IssueService();
